import {
  Disclosure,
  DisclosurePanel,
  DisclosureButton,
} from "@headlessui/react";
import { Squash as Hamburger } from "hamburger-react";

import { useEffect, useState } from "react";
import Link from "next/link";
import clsx from "clsx";

import { useRouter } from "next/router";
import { useStore } from "~/store";

export default function Nav({ hideLogo = false }: { hideLogo?: boolean }) {
  const router = useRouter();
  const currentRoute = router.pathname;

  const userAddress = useStore((state) => state.userAddress);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const navigation = [
    { name: "Times", href: userAddress && isClient ? "/" : "/welcome" },
    { name: "FAQ", href: "/faq" },
    { name: "Resources", href: "/resources" },
    // { name: "UI", href: "/ui" },
  ];

  return (
    <Disclosure as="nav" className="">
      {({ open }) => (
        <>
          <div className="">
            <div className="relative flex items-center justify-between">
              <div className="flex flex-1 items-stretch justify-between">
                <div>
                  {hideLogo === false && (
                    <Link
                      className={clsx(
                        "flex flex-shrink-0 items-center gap-4 text-sm",
                      )}
                      href={userAddress && isClient ? "/" : "/welcome"}
                    >
                      {/* eslint-disable @next/next/no-img-element */}
                      <img
                        className="block h-10 w-auto"
                        src="/logo.svg"
                        alt="Emergency Department Wait Times logo"
                        width={50}
                        height={50}
                      />
                      <div className="text-md">
                        <div className="font-bold">Emergency Department</div>
                        <span className="">Wait Times</span>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className={clsx(
                          currentRoute === item.href
                            ? " text-blue"
                            : "text-gray-300 hover:text-blue",
                          "rounded-md px-3 py-2 text-sm font-medium uppercase",
                        )}
                        aria-current={
                          currentRoute === item.href ? "page" : undefined
                        }
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center sm:ml-6 sm:hidden ">
                <div className="inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <DisclosureButton className="text-gray-400 inline-flex items-center justify-center hover:text-blue focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <Hamburger
                      toggled={open}
                      size={24}
                      label="Hamburger Menu"
                    />
                  </DisclosureButton>
                </div>
              </div>
            </div>
          </div>

          <DisclosurePanel className="absolute left-0 w-full bg-white sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as={Link}
                  href={item.href}
                  className={clsx(
                    currentRoute === item.href
                      ? "text-blue"
                      : "text-gray-300 hover:text-blue",
                    "block rounded-md px-3 py-2 text-base font-medium",
                  )}
                  aria-current={currentRoute === item.href ? "page" : undefined}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}
