import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { Coordinates } from "@edwt/cms/prisma/getLocations";
import { AudienceValue } from '@edwt/cms/schema/fields/audience/options';
import { geocode } from '~/lib/mapping';


// Geocode Cache Types

export type UserDistance = {
    address: string;
    distanceInMeters: number;
};

export type GeocodeCache = {
    userCoordinates: Coordinates | null;
};

export const initialGeocodeCache: GeocodeCache = {
    userCoordinates: null,
};

// Filter Types

export type OpenOptions = null | "open";
export type TypeOptions = null | "ed" | "upcc";
export type StarredOptions = null | "starred";

export type FilterState = {
    open: OpenOptions;
    type: TypeOptions;
    age: AudienceValue | null;
    starred: StarredOptions;
};

export type FilterID = keyof FilterState;

export type FilterOption<ValueType extends string | null> = {
    label: string;
    value: ValueType;
}

export type Filter<ValueType extends string> = {
    name: string;
    id: FilterID;
    clearLabel: string;
    options: FilterOption<ValueType>[];
}

const initialFilterState: FilterState = Object.freeze({
    open: null,
    type: null,
    age: null,
    starred: null,
});


export const useStore = create<{
    // State
    geocodeCache: GeocodeCache,
    userAddress: string | null,
    starredLocations: string[],
    filters: FilterState,
    userShowFilters: boolean,
    hideLocationDisclaimer: boolean,
    hasHydrated: boolean,

    // Setters
    setUserAddress: (newAddress: string, coordinates?: Coordinates) => Promise<void>,

    clearGeocode: () => void,

    starLocation: (edSlug: string) => void,
    unstarLocation: (edSlug: string) => void,

    clearFilters: () => void,
    showFilters: () => void,
    getIsFiltering: () => boolean,
    getShowFilters: () => boolean,
    setFilters: (filters: FilterState) => void,
    setHasHydrated: (hasHydrated: boolean) => void,

    toggleLocationDisclaimer: () => void,
}>()(
    persist(
        (set, get) => ({

            // User's address
            userAddress: "",
            setUserAddress: async (newAddress: string, coordinates?: Coordinates) => {

                try {
                    // if user coordinates are not explicity passed into the set function, use geocoder to infer them from address
                    if (!coordinates) {
                        const newUserCoordinates = newAddress && newAddress !== "" ? await geocode(newAddress) : null;

                        set({ userAddress: newAddress, geocodeCache: { ...initialGeocodeCache, userCoordinates: newUserCoordinates }, filters: structuredClone(initialFilterState) })
                    }
                    // Else, pass address and coordinates directly to set method
                    else {
                        set({ userAddress: newAddress, geocodeCache: { ...initialGeocodeCache, userCoordinates: coordinates }, filters: structuredClone(initialFilterState) })
                    }

                } catch (error) {
                    throw error;
                }

            },

            // Cache of Geocoded addresses
            geocodeCache: initialGeocodeCache,
            clearGeocode: () => set({ geocodeCache: initialGeocodeCache }),

            // User Starred Emergency Departments
            starredLocations: [],
            starLocation: (edSlug: string) => {
                const starredLocations = new Set(
                    get().starredLocations
                );
                starredLocations.add(edSlug);
                set({
                    starredLocations: Array.from(starredLocations),
                });
            },
            unstarLocation: (edSlug: string) => set({ starredLocations: get().starredLocations.filter((x) => x !== edSlug) }),

            // Filters
            filters: structuredClone(initialFilterState),
            userShowFilters: false,
            showFilters: () => set({ userShowFilters: true }),
            getShowFilters: () => get().userShowFilters || get().getIsFiltering(),
            getIsFiltering: () => !!Object.values(get().filters).find((x) => x !== null),
            setFilters: (filters: FilterState) => set({ filters: structuredClone(filters) }),
            clearFilters: () => {
                set({ filters: structuredClone(initialFilterState), userShowFilters: false })
            },

            // Location Disclaimer
            hideLocationDisclaimer: false,
            toggleLocationDisclaimer: () => set({ hideLocationDisclaimer: !get().hideLocationDisclaimer }),

            // Hydration State
            hasHydrated: false,
            setHasHydrated: state => {
                set({
                    hasHydrated: state,
                });
            },

        }),
        {
            name: 'edwt-state',
            version: 1,
            // Don't persist the following store state
            partialize: (state) =>
                Object.fromEntries(
                    Object.entries(state).filter(([key]) => !['hideLocationDisclaimer', 'hasHydrated'].includes(key)),
                ),
            // Update hydration state
            onRehydrateStorage: () => state => {
                state?.setHasHydrated(true);
            },
        }
    )
)



