import MapboxClient from "@mapbox/mapbox-sdk";
import type MapiClient from "@mapbox/mapbox-sdk/lib/classes/mapi-client";
import Geocoding, { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import Directions from '@mapbox/mapbox-sdk/services/directions';

import { Coordinates } from "~/../cms/prisma/getLocations";
import { env } from "~/env";


/** Create client singleton to prevent new client on every request */
const client = (function () {
    let instance: MapiClient | null = null;

    return () => {
        if (!instance) {
            instance = MapboxClient({ accessToken: env.NEXT_PUBLIC_MAP_API });
        }
        return instance;
    };
})();

/** Singleton cache object */
const searchCache = (function () {
    let cache: { [query: string]: MappingSearchResults } = {};

    return {
        get(query: string) {
            return cache[query.toUpperCase().trim()];
        },
        set(query: string, results: MappingSearchResults) {
            cache[query.toUpperCase().trim()] = results;
        },
        clear() {
            cache = {};
        },
        all() {
            return cache;
        }
    };
})();


/**
 * Converts an address into coordinates via a geocoding API
 * @param address Address you wish to geocode
 * @returns 
 */
export async function geocode(address: string) {

    const mapboxClient = client();

    const geocoding = Geocoding(mapboxClient);

    // Geocode address
    const { body } = await geocoding.forwardGeocode({
        query: address,
        limit: 1,
        // @ts-ignore
        proximity: "ip",
        countries: ["CA"],
        autocomplete: false,

    }).send()

    // If the search returned a result, return the coordinates
    if (body.features.length > 0) {
        const feature = body.features[0];

        return getCoordinatesFromFeature(feature)
    }
    else throw new Error("Could Not Geocode");
}
export async function reverseGeocode(coordinates: Coordinates): Promise<GeocodeFeature> {

    const mapboxClient = client();

    const geocoding = Geocoding(mapboxClient);

    // Lookup coordinates
    const { body } = await geocoding.reverseGeocode({
        query: [coordinates.long, coordinates.lat],
        limit: 1,
        // proximity: "ip",
        countries: ["CA"],
        // autocomplete: false,
    }).send()

    if (body.features.length > 0) {
        const feature = body.features[0];

        return feature;
    }
    else throw new Error("Could Not Geolocate");
}
export function getCoordinatesFromFeature(feature: GeocodeFeature): Coordinates {
    const [longitude, latitude] = feature.center;

    return {
        long: longitude,
        lat: latitude
    }
}
/**
 * Provides the driving distance in meters between 2 pairs of coordinates
 * @param to Destination coordinates
 * @param from Starting Coordinates
 * @returns 
 */
export async function distance(to: Coordinates, from: Coordinates): Promise<number> {

    const mapboxClient = client();

    const directions = Directions(mapboxClient);


    // [longitude, latitude]


    const { body } = await directions.getDirections({
        profile: "driving",
        waypoints: [
            {
                coordinates: [to.long, to.lat],
            },
            {
                coordinates: [from.long, from.lat],
            },
        ],
    }).send()

    // console.log(body.routes[0])

    return body.routes[0].distance;

}

export type MappingSearchResult = {
    id: string;
    text: string;
    coordinates: Coordinates
}

export type MappingSearchResults = MappingSearchResult[];

export async function autocomplete(query: string): Promise<MappingSearchResults> {

    if (!query) return [];

    // Check cache first
    const cachedResults = searchCache.get(query);
    if (cachedResults) {
        return cachedResults;
    }

    const mapboxClient = client();

    const geocoding = Geocoding(mapboxClient);

    const response = await geocoding
        .forwardGeocode({
            query: query,
            types: ["address", "place"],
            routing: false,
            // Bounding box roughly constrained around BC
            bbox: [-139, 49, -118, 60],
            countries: ["CA"],
            limit: 10,
            autocomplete: true,
            language: ["en-CA"],
            proximity: "ip"

        })
        .send();

    const results = response.body.features.map((feature) => ({
        id: feature.id,
        text: feature.place_name,
        coordinates: getCoordinatesFromFeature(feature)
    }));

    // Update cache with the new results
    searchCache.set(query, results);

    return results;
}